import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Контакти | Корти Альтаїр
			</title>
			<meta name={"description"} content={"Опануйте свою подачу під нашим світлом"} />
			<meta property={"og:title"} content={"Контакти | Корти Альтаїр"} />
			<meta property={"og:description"} content={"Опануйте свою подачу під нашим світлом"} />
			<meta property={"og:image"} content={"https://clarivistaboom.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://clarivistaboom.com/img/5501904.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://clarivistaboom.com/img/5501904.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://clarivistaboom.com/img/5501904.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://clarivistaboom.com/img/5501904.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://clarivistaboom.com/img/5501904.png"} />
			<meta name={"msapplication-TileImage"} content={"https://clarivistaboom.com/img/5501904.png"} />
			
		</Helmet>
		<Components.Header />
		<Section background="--color-darkL2" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Зв'яжіться з нами
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
				Пориньте у світ Альтаїр тенісні корти вже сьогодні. Для бронювання, запитів або просто для того, щоб поговорити про вашу любов до гри, зв'яжіться з нами:
				</Text>
				<Link
					href="mailto:contact@clarivistaboom.com"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					contact@clarivistaboom.com
				</Link>
				<Link
					href="tel:068 414 13 53"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					068 414 13 53
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
				вулиця Річна, 20, Хмельницький,<br/>Хмельницька область, Україна 29000
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});